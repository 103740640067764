const ROUTES = {
  HOME: {
    href: '/',
  },
  REWARD_AD_PLUS: {
    label: 'header.menu.rewardAdPlus',
    href: '/reward-ad-plus',
  },
  ADVERTISERS: {
    label: 'Advertisers',
    href: '/advertisers',
  },
  PUBLISHERS: {
    label: 'Publishers',
  },
  PUBLISHERS_MONETIZE: {
    label: 'Monetize',
    href: '/publishers/monetize',
  },
  PUBLISHERS_ADSERVER: {
    label: 'Adserver',
    href: '/publishers/adserver',
  },
  PUBLISHERS_OFFERWALL: {
    label: 'Offerwall',
    href: '/publishers/offerwall',
  },
  RESOURCE: {
    label: 'Resource',
  },
  RESOURCE_MONETIZE: {
    label: 'Monetize',
    href: 'https://adpopcornssp.gitbook.io/ssp-sdk',
  },
  RESOURCE_REWARD: {
    label: 'Reward',
    href: 'https://adpopcorn.notion.site/Reward-7651ba9040d24298bb1edf684b6e0b5a',
  },
  CONTACT: {
    label: 'Contact Us',
    href: '/contact',
  },
  CONTACT_PUBLISHER: {
    label: 'Publishers',
    href: '/contact#publishers',
  },
  FAMILY_SITE: {
    label: 'Family Site',
  },
  CONTACT_ADVERTISER: {
    label: 'Advertisers',
    href: '/contact#advertisers',
  },
  TRADINGWORKS: {
    label: 'Tradingworks',
    href: 'http://www.tradingworks.com/',
  },
  ADBRIX: {
    label: 'Adbrix',
    href: 'https://adbrix.io/',
  },
  DIFINERY: {
    label: 'Dfinery',
    href: 'https://www.dfinery.io/',
  },
  MOBILE_INDEX: {
    label: 'MobileIndex',
    href: 'https://www.mobileindex.com/',
  },
};

export default ROUTES;
