import { Header } from 'components/policy';
import { Media20211031 } from 'components/policy';

const PolicyMedia = () => {
  return (
    <>
      <Header />
      <Media20211031 />
    </>
  );
};

export default PolicyMedia;
